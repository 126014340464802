<template>
  <form @submit="save()">
    <div class="form-group">
      <label for>Almacén</label>
      <SelectStore ref="selSto" v-model="reg.store_id"></SelectStore>
    </div>
    <div class="form-group">
      <label for>Producto</label>
      <SelectProduct ref="selPro" v-model="reg.product_id"></SelectProduct>
    </div>
    <div class="form-group">
      <label for>Stock Minimo</label>
      <app-input-number v-model="reg.stock_min"></app-input-number>
    </div>
    <!-- <div class="form-group">
      <label for>Duracion</label>
      <app-input-number v-model="reg.duration"></app-input-number>
    </div> -->
    <app-button-submit @click="save()"></app-button-submit>
  </form>
</template>

<script>
import SelectStore from "../stores/Select";
import { LogisticService } from "../service";
import SelectProduct from "../../store-module/products/Select";

export default {
  components: {
    SelectStore,
    SelectProduct
  },
  data: () => ({
    reg: {}
  }),
  methods: {
    loadReg(reg) {
      this.reg = JSON.parse(JSON.stringify(reg));
      this.$refs.selSto.setValueFromId(reg.store_id);
      this.$refs.selPro.setValueFromId(reg.product_id);
    },
    save() {
      LogisticService.saveStockConfig(this.reg).then((res) =>
        this.$emit("submitted", res)
      );
    },
    reset() {
      this.reg = {};
      this.$refs.selSto.reset();
      this.$refs.selPro.reset();
    }
  }
};
</script>

<style></style>
